import MainLayout from "../../layouts/MainLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import footer from "../../assets/images/bottom.png";

import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import store from "../../store/store";
import { canClaim, setAccessToken } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
// import PaymentPage from "../PaymentPage/PaymentPage";
// import CashbackPayout from "../CashbackPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addCaptchaScript, hideCaptchaBadge } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { log } from "console";
import arrow from "../../assets/images/arrow.png";
import register from "../../assets/images/Register.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
type StateResponseProps = {
  state: string;
};

const hardcodedStates = [
  { state: "West Bengal" },
  { state: "Uttarakhand" },
  { state: "Uttar Pradesh" },
  { state: "Tripura" },
  { state: "Telangana" },
  { state: "Sikkim" },
  { state: "Rajasthan" },
  { state: "Punjab" },
  { state: "Puducherry" },
  { state: "Odisha" },
  { state: "Nagaland" },
  { state: "Mizoram" },
  { state: "Meghalaya" },
  { state: "Manipur" },
  { state: "Maharashtra" },
  { state: "Madhya Pradesh" },
  { state: "Lakshadweep" },
  { state: "Ladakh" },
  { state: "Kerala" },
  { state: "Karnataka" },
  { state: "Jharkhand" },
  { state: "Jammu and Kashmir" },
  { state: "Himachal Pradesh" },
  { state: "Haryana" },
  { state: "Gujarat" },
  { state: "Goa" },
  { state: "Delhi" },
  { state: "Nagar Haveli" },
  { state: "Dadra" },
  { state: "Daman" },
  { state: "Diu" },
  { state: "Chhattisgarh" },
  { state: "Chandigarh" },
  { state: "Bihar" },
  { state: "Assam" },
  { state: "Arunachal Pradesh" },
  { state: "Andhra Pradesh" },
  { state: "Andaman and Nicobar Islands" },
  { state: "Tamil Nadu" },
  // Add more states as needed
];

const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [filteredCities, setFilteredCities] = useState<StateResponseProps[]>(
    []
  );
  const [filteredNewStates, setFilteredNewStates] = useState<
    StateResponseProps[]
  >([]);
  const [citiesFound, setCitiesFound] = useState(true);
  const [states, setStates] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const handleStateFieldClick = () => {
    setShowStateDropdown(!showStateDropdown);
  };

  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };
  useEffect(() => {
    setFilteredNewStates(filteredCities);
  }, [filteredCities]);

  useEffect(() => {
    // console.log(filteredCities);
    // console.log(filteredNewStates);
    {
      setFilteredCities(hardcodedStates);
    }
  }, []);

  useEffect(() => {
    // console.log("Show State Dropdown:", showStateDropdown);
  }, [showStateDropdown]);

  //State Dropdown:
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();

    const filtered = filteredCities.filter((state) =>
      state.state.toLowerCase().includes(inputValue)
    );

    setCitiesFound(filtered.length > 0);

    setFilteredNewStates(filtered);
    setShowStateDropdown(true);
  };

  const handleStateSelect = (selectedState: string) => {
    // console.log("modal clicke", selectedState);

    setStates(selectedState);
    setSelectedState(selectedState);
    // console.log("states", states);
    setShowStateDropdown(false);
  };
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     // If the user is logged in, redirect to payoutform
  //     // navigate(ROUTES.PAYMENT_PAGE);
  //     navigate(ROUTES.PAYOUT_FORM);
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        // Perform your back navigation logic here
        navigate(ROUTES.PAYOUT_FORM); // Change this to the appropriate route
      };

      window.addEventListener("popstate", handleLocationChange);

      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, isLoggedIn]);

  const RegisterValidation = Yup.object().shape({
    mobile: Yup.string()
      .required("*Please enter valid 10-digit number")
      .matches(
        /^[56789][0-9]{9}$/,

        "*Please enter valid 10-digit number"
      ),
    name: Yup.string()
      .required("Enter valid name")
      .matches(/^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/, "Enter valid name"),

    code: Yup.string().required(
      t("registerPage.errors.invalidCode") ||
        "*That’s not a valid code. Please check and enter the correct code"
    ),
    state: Yup.string().required("*Please enter a valid State"),
    agree: Yup.boolean().oneOf(
      [true],
      " Please accept Terms and Conditions to proceed"
    ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
          "*You have entered an incorrect OTP. Please try again"
      ),
  });

  // const navigate = useNavigate();

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  {
    /*useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );*/
  }

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          code: "",
          mobile: "",
          name: "",
          state: states,
          // smocker: true,
          agree: false,
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          // console.log(values);
          setShowStateDropdown(false);
          // setShowOtpForm(true);
          if (values.agree) {
            API.register(values).then(() => {
              gtagTrackEvent(GA_EVENTS.Registered);
              setShowOtpForm(true);
              startTimer();
            });
          } else {
            setCheckboxError("Please accept the terms and conditions");
            setShowError(true); // Optionally, show an error message
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="register-form">
                <p className="form-title ">REGISTER</p>

                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.code}
                    name="code"
                    maxLength={12}
                    onBlur={handleBlur}
                    placeholder="ENTER UNIQUE CODE"
                  />
                </div>

                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    maxLength={20}
                    name="name"
                    onBlur={handleBlur}
                    placeholder="ENTER NAME"
                    pattern="[A-Za-z\s]*"
                  />
                </div>
                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.mobile}
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                    placeholder="ENTER MOBILE NUMBER"
                  />
                </div>

                <div className="input-group">
                  <select
                    name="state"
                    className="details-input"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.state}
                    onClick={() => setShowStateDropdown(true)}
                  >
                    <option value="" label="ENTER STATE" disabled />
                    {filteredNewStates.map((state) => (
                      <option key={state.state} value={state.state}>
                        {state.state}
                      </option>
                    ))}
                    {!filteredNewStates.length ? (
                      <option value="" className="nf">
                        Not Found
                      </option>
                    ) : null}
                  </select>
                </div>

                <div className="input-group flex-center">
                  <label className="checkbox-container">
                    <span>
                      {" "}
                      I ACCEPT THE{" "}
                      <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
                        <u className="terms-link">TERMS AND CONDITIONS{""}</u>
                      </span>
                      <br />
                    </span>
                    <input
                      type="checkbox"
                      id="agree"
                      name="agree"
                      onChange={(e) => {
                        setFieldValue("agree", e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                {errors.code && touched.code && (
                  <p className="error">{errors.code}</p>
                )}
                {!errors.code && errors.name && touched.name && (
                  <p className="error">{errors.name}</p>
                )}

                {!errors.code &&
                  !errors.name &&
                  errors.mobile &&
                  touched.mobile && <p className="error">{errors.mobile}</p>}
                {!errors.code &&
                  !errors.name &&
                  !errors.mobile &&
                  errors.state &&
                  touched.state && <p className="error">{errors.state}</p>}

                {!errors.code &&
                  !errors.name &&
                  !errors.mobile &&
                  !errors.state &&
                  errors.agree &&
                  touched.agree && <p className="error">{errors.agree}</p>}

                <button className="btn btn-primary" type="submit">
                  SUBMIT
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.PAYOUT_PAGE);
          // navigate(ROUTES.MCQ);
          API.verifyOTP(values.otp, "")
            .then((response) => {
              hideCaptchaBadge();
              // console.log("Access Token:", response.accessToken);
              store.dispatch(setAccessToken(response.accessToken));
              store.dispatch(canClaim(response.canClaim));
              gtagTrackEvent(GA_EVENTS.Verify_OTP);
              const questionData = response.data;
              // console.log("response", response);
              // console.log("response.data", response.data);
              // setShowPayoutForm(true);
              // setShowPaymentPage(true);
              // navigate(ROUTES.MCQ);
              if (response?.messageId === "getReward") {
                const cashback = response.cashback;
                return navigate(ROUTES.REWARD, { state: { cashback } });
              }

              navigate(ROUTES.MCQ, { state: { questionData } });

              // navigate(ROUTES.PAYMENT_PAGE);
            })
            .catch((err) => {
              const { messageId } = err;
              switch (messageId) {
                case ERROR_IDS.INVALID_OTP:
                  errors.setErrors({
                    otp:
                      t("registerPage.errors.invalidOtp") ||
                      "Please enter a valid OTP",
                  });
                  break;
                default:
                  errors.setErrors({
                    otp: err.message,
                  });
                  break;
              }
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <div className="input-group">
                  <p className="form-title">ENTER OTP</p>
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.otp")}
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
                <button className="btn btn-primary" type="submit">
                  VERIFY
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
