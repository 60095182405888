import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import { title } from "process";

const PrivacyPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="howtoredeem-us-popup" title="PRIVACY POLICY">
      <p className="text1">coming soon</p>
    </Popup>
  );
};

export default PrivacyPopup;
