import "./PhonePay.scss";
import MainLayout from "../../layouts/MainLayout";
// import LogoTop from "../../assets/images/bg/Logo.svg";
// import Text from "../../assets/images/oth/text-lite.svg";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
// import { QuestionItem } from "../../interface/api";
import { useDispatch, useSelector } from "react-redux";
// import {
//   isFirstGame,
//   setIndividualScore,
// } from "../../store/actions/authAction";
import { useAuthentication } from "../../hooks/useAuthentication";
import { RootState } from "../../store/store";
import { GA_EVENTS, gtagTrackEvent } from "../../lib/utils";
import congrats from "../../assets/images/congrats.png";

interface QuestionItem {
  id: number;
  question: string;
  option1: string;
  option2: string;
  option3: string;

  correctOption: number;
}

interface redeemAnswer {
  id: number;
  option: number;
  gameKey: string;
}

export const PhonePay = () => {
  const { isLoggedIn } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<QuestionItem | null>(null);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | any>(null);
  const [optionClicked, setOptionClicked] = useState<boolean>(false);
  //   const GameKey = useSelector((state: RootState) => state.authReducer.gameKey);

  useEffect(() => {
    // fetchNextQuestion();
  }, []);

  const handleOptionClick = async (option: number) => {
    // if (!optionClicked) {
    // setOptionClicked(true);
    setSelectedOption(option);

    // Simulate answer checking for now
    const isAnswerCorrect = option === question?.correctOption;
    setIsCorrect(isAnswerCorrect);

    // if (isAnswerCorrect) {
    //   //   gtagTrackEvent(GA_EVENTS.PAYMENT_PAGE);
    //   navigate(ROUTES.PAYMENT_PAGE); // Navigate to payment page on correct answer
    // }
    // }
  };
  const handleSubmit = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <MainLayout className="register-page">
      <div className="reward-phonepey-form">
        <div className="congratulation-container">
          {/*<img src={Text} alt="logo-top" />*/}
          <p className="congratulation-title">CONGRATULATIONS</p>
          <img src={congrats} />

          <p className="congratulation-text">
            YOUR PHONE PE VOUCHER WILL BE SENT TO YOUR REGISTERED MOBILE NUMBER.
          </p>
        </div>
      </div>
      <button
        className="btn btn-primary back-btn"
        onClick={handleSubmit}
        // disabled={!optionClicked}
      >
        BACK TO HOME
      </button>
    </MainLayout>
  );
};
