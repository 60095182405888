import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./locales/en.json"),
    },
    gu: {
      translations: require("./locales/gu.json"),
    },
    hi: {
      translations: require("./locales/hi.json"),
    },
    kn: {
      translations: require("./locales/kn.json"),
    },
    ml: {
      translations: require("./locales/ml.json"),
    },
    ta: {
      translations: require("./locales/ta.json"),
    },
    te: {
      translations: require("./locales/te.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "gu", "hi", "kn", "ml", "ta", "te"];

export default i18n;
