import { useSelector } from "react-redux";
import { UserReducerStore } from "../store/reducers/userReducer";
import { RootState } from "../store/store";

export const useAuthentication = () => {
  const accessDetails = useSelector((state: RootState) => state.authReducer);
  const userDetails: UserReducerStore = useSelector(
    (state: any) => state.userReducer
  );
  return {
    isLoggedIn: !!accessDetails.accessToken,
    accessToken: accessDetails.accessToken,
    accessDetails,
    user: userDetails,
    userKey: accessDetails.userKey,
  } as const;
};
