import "./UpiPage.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import API from "../../api";

export const UpiPage = () => {
  const navigate = useNavigate();
  const [upi_id, setupi_id] = useState<string>("");
  const [upi_idError, setupi_idError] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<string>("");

  const handleSubmit = () => {
    if (!validateupi_id(upi_id)) {
      setupi_idError("Enter valid UPI ID format");
      return;
    }
    if (!isChecked) {
      setCheckboxError("Please accept the dislaimer to Proceed");
      return;
    }
    // Call backend with UPI ID
    API.saveUPIDetails(upi_id)
      .then((response) => {
        // console.log("UPI ID saved successfully:", response);
        navigate(ROUTES.UPI_REWARD);
      })
      .catch((error) => {
        console.error("Error saving UPI ID:", error);
        setupi_idError("Failed to save UPI ID. Please try again.");
      });
    // console.log("UPI ID:", upi_id);
    navigate(ROUTES.UPI_REWARD);
  };

  const validateupi_id = (id: string) => {
    const upiRegex = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
    return upiRegex.test(id);
  };

  return (
    <MainLayout className="register-page">
      <div className="redeem-form">
        <div className="game-container">
          <p className="payment-title">REDEEM CASHBACK</p>
          <div className="redeem-section">
            <div className="upi-input">
              <input
                type="text"
                placeholder="ENTER UPI ID"
                value={upi_id}
                onChange={(e) => {
                  setupi_id(e.target.value);
                  setupi_idError("");
                }}
              />
              {upi_idError && <p className="error">{upi_idError}</p>}
            </div>
            <div className="input-group flex-center">
              <label className="checkbox-container-upi">
                <span>
                  I hereby irrevocably and absolutely appoint Pine Labs Private
                  Limited as my agent for the purposes of facilitating the
                  transfer of amounts due from ITC Limited to me under this ITC
                  Classmate Invento Program and expressly share my personal
                  information for this purpose. I further consent to and
                  authorise Pine Labs Private Limited to process and store my
                  personal information (including any sensitive personal data)
                  <br />
                </span>
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                    setCheckboxError("");
                  }}
                />
                <span className="checkmark"></span>
              </label>
              {checkboxError && <p className="error">{checkboxError}</p>}
            </div>
            <button className="btn btn-primary" onClick={handleSubmit}>
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
