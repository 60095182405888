import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ConsentPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup" title="Withdraw Consent">
      <p className="text3">
        <a className="text3">
          {" "}
          To withdraw your consent for sharing personal information for this
          promotion, please email <br />
          <br />
          <strong>
            <a className="text4" href={"mailto:Consentremoval@pinelabs.com"}>
              Consentremoval@pinelabs.com
            </a>
          </strong>
        </a>
      </p>
    </Popup>
  );
};

export default ConsentPopup;
