import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup" title="Contact us">
      <p className="text3">
        <a className="text3" href={"classmatesupport@pinelabs.com"}>
          <strong>Email ID:</strong> classmatesupport@pinelabs.com
        </a>
      </p>
      {/*<p className="text3">
        <p>
          <strong>Customer Support:</strong> 080-69806393
        </p>
  </p>*/}
    </Popup>
  );
};

export default ContactUsPopup;
