import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import Illustration from "../../assets/images/illustration.png";
import Illustrationdesktop from "../../assets/images/illustration-desktop.png";
import cashback from "../../assets/images/cashback.png"

import "./Home.scss";
import { ROUTES } from "../../lib/consts";
import API from "../../api";
import { Trans, useTranslation } from "react-i18next";
import { useGlobalModalContext } from "../../helpers/GlobalModal";
import { useEffect, useRef, useState } from "react";
import { LANGUAGE, LANGUAGES } from "../../lib/consts";
import { useAuthentication } from "../../hooks/useAuthentication";

const Home = () => {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useAuthentication();
  const location = useLocation();
  useEffect(() => {
    if (isLoggedIn) {
      // If the user is logged in, redirect to payoutform
      // navigate(ROUTES.PAYOUT_FORM);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        // Perform your back navigation logic here
        // navigate(ROUTES.PAYOUT_FORM);
      };
      window.addEventListener("popstate", handleLocationChange);
      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, location.pathname, isLoggedIn]);
  const getStarted = () => {
    navigate(ROUTES.REGISTER);

    // console.log("1");
    // API.getStarted("get_started")
    //   .then((res) => {
    //     console.log("2 :", res);
    //     navigate(ROUTES.REGISTER);
    //   })
    //   .catch((error) => {
    //     console.log("2 :", error);
    //     setShowError(true); // Set showError to true for UI feedback
    //     console.error("Error in API.getStarted:", error);
    //     if (error.code === 401) {
    //       console.log("3 :", 401);
    //       console.error("User not authenticated:", error.message);
    //     }
    //   });
  };
  return (
    <div className="home-page">
      <div className="content">
        <img src={Logo} alt="logo" className="logo" />
        <img src={Illustration} alt="illustration" className="illustration" />
        <img
          src={Illustrationdesktop}
          alt="illustration-desktop"
          className="illustration-desktop"
        />

        {/* <img src={cashback}
        alt="cashback"
        className="cashback-image"
        /> */}

        {/* <div className="text-wrapper"></div>
        <div className="content-wrapper">
        <div className="get-started-container" onClick={getStarted}>
        <span className="get-started-text">GET STARTED</span>
        </div>
        </div> */}
        <p className="closed">Thank you for your interest,<br/>
        this contest is now closed</p>
      </div>
    </div>
  );
};

export default Home;
