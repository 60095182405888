import { Route, Routes } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
// import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import { useEffect, useState } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { addCaptchaScript, logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";

import Thankyou from "./pages/Design/Thankyou/Thankyou";
import { useNavigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Quiz } from "./pages/ouiz/Quiz";
import { RedeemPayment } from "./pages/RedeemPayment/RedeemPayment";
import { Reward } from "./pages/Reward/Reward";
import { Congratulation } from "./pages/Congratulation/Congratulation";
import { PhonePay } from "./pages/PhonePay/PhonePay";
import { UpiPage } from "./pages/UpiPage/UpiPage";
import { UpiReward } from "./pages/UpiReward/UpiReward";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();
  // const [selectedStyle, setSelectedStyle] = useState<string | null>(null);
  // const [selectedValue, setSelectedValue] = useState<string | null>(null);
  // const [selectedElement, setSelectedElement] = useState<string | null>(null);
  // const [selectedColor, setSelectedColor] = useState<string | null>("warm");
  // const [text, setText] = useState<string | null>("");
  const navigate = useNavigate();

  useEffect(() => {
    API.initialize(showLoader, hideLoader);
    API.createUser().then((response: any) => {
      store.dispatch(setUserIdentification(response));
      if (!response.isLoggedIn && isLoggedIn) {
        logoutUser();
        toast.info("Your session has been expired");
      }
      // addCaptchaScript();
    });
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      logoutUser();
      navigate(ROUTES.REGISTER);
      toast.info("Your session has been expired");
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route
          path={ROUTES.MCQ}
          element={
            <PrivateRoute>
              <Quiz />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.REDEEM}
          element={
            <PrivateRoute>
              <RedeemPayment />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.REWARD}
          element={
            <PrivateRoute>
              <Reward />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CONGRATULATION}
          element={
            <PrivateRoute>
              <Congratulation />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.UPI_PAGE}
          element={
            <PrivateRoute>
              <UpiPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PHONEPAY}
          element={
            <PrivateRoute>
              <PhonePay />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.UPI_REWARD}
          element={
            <PrivateRoute>
              <UpiReward />
            </PrivateRoute>
          }
        />

        {/* <Route
          path={ROUTES.PAYMENT_PAGE}
          element={
            // <PrivateRoute>
            <PaymentPage onSubmit={(values) => console.log(values)} />
            // </PrivateRoute>
          }
        />*/}

        {/*<Route path={ROUTES.COMPLETED} element={<Completed />} />*/}
      </Routes>
      <ToastContainer position="bottom-center" hideProgressBar={true} />
    </div>
  );
}

export default App;
