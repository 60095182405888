import "./RedeemPayment.scss";
import MainLayout from "../../layouts/MainLayout";
// import LogoTop from "../../assets/images/bg/Logo.svg";
// import Text from "../../assets/images/oth/text-lite.svg";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
// import { QuestionItem } from "../../interface/api";
import { useDispatch, useSelector } from "react-redux";
// import {
//   isFirstGame,
//   setIndividualScore,
// } from "../../store/actions/authAction";
import { useAuthentication } from "../../hooks/useAuthentication";
import { RootState } from "../../store/store";
import { GA_EVENTS, gtagTrackEvent } from "../../lib/utils";

export const RedeemPayment = () => {
  const navigate = useNavigate();
  const [paymentOptions, setPaymentOptions] = useState([
    "Amazon pay",
    "Phone Pe",
    "UPI",
  ]);
  const [selectedOption, setSelectedOption] = useState<String | null>(null);
  const [optionClicked, setOptionClicked] = useState<boolean>(false);

  const handleOptionClick = async (option: String) => {
    setSelectedOption(option);
  };
  const handleSubmit = () => {
    if (selectedOption === null) return;
    // call backend with selected option
    // console.log("Selected option:", selectedOption);

    if (selectedOption === "UPI") {
      gtagTrackEvent(GA_EVENTS.UPI_click);
      return navigate(ROUTES.UPI_PAGE);
    }
    if (selectedOption === "Phone Pe") {
      API.savePhonePe();
      gtagTrackEvent(GA_EVENTS.Phonepe_click);
      return navigate(ROUTES.PHONEPAY);
    }
    API.saveAmazonDetails();
    gtagTrackEvent(GA_EVENTS.Amazon_Pay);
    return navigate(ROUTES.CONGRATULATION);
  };

  return (
    <MainLayout className="register-page">
      <div className="redeem-payment-form">
        <div className="game-container">
          {/*<img src={Text} alt="logo-top" />*/}
          <p className="payment-title">REDEEM CASHBACK</p>

          {paymentOptions && (
            <div className="redeem-section">
              <section className="redeem-options">
                {paymentOptions.map((paymentOption) => (
                  <button
                    style={{
                      backgroundColor:
                        selectedOption === paymentOption ? "yellow" : "",
                      color: selectedOption === paymentOption ? "black" : "",
                    }}
                    onClick={() => handleOptionClick(paymentOption)}
                    disabled={optionClicked}
                  >
                    {paymentOption}
                  </button>
                ))}
              </section>
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                // disabled={!optionClicked}
              >
                SUBMIT
              </button>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
