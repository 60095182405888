import { getCookie, setCookie } from "../../lib/utils";
import {
  SET_USER_ID,
  CLEAR_ACCESS_DETAILS,
  SET_ACCESS_TOKEN,
  CAN_CLAIM,
  ALREADY_DESIGNED,
} from "../types";

const initialState = {
  userKey: "",
  dataKey: "",
  accessToken: "",
  canClaim: false,
  alreadyDesigned: false,
};

type ActionType =
  | {
      type: "SET_USER_ID";
      payload: {
        userKey: string;
        dataKey: string;
      };
    }
  | {
      type: "SET_ACCESS_TOKEN";
      payload: string;
    }
  | {
      type: "CLEAR_ACCESS_DETAILS";
    }
  | {
      type: "ALREADY_DESIGNED";
      payload: {
        alreadydesigned: boolean;
      };
    }
  | {
      type: "CAN_CLAIM";
      payload: {
        canClaim: boolean;
      };
    };
export default function authReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_USER_ID:
      let masterKey = getCookie("classmate-id");
      if (!masterKey) {
        setCookie("classmate-id", action.payload.userKey);
      }
      // console.log("userKey", action.payload.userKey);
      return {
        ...state,
        userKey: action.payload.userKey,
        dataKey: action.payload.dataKey,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case CLEAR_ACCESS_DETAILS:
      return {
        ...state,
        userKey: "",
        dataKey: "",
        accessToken: "",
      };
    case CAN_CLAIM:
      return {
        ...state,
        canClaim: action.payload.canClaim,
      };
    case ALREADY_DESIGNED:
      // console.log(action.payload);
      return {
        ...state,
        alreadyDesigned: action.payload.alreadydesigned,
      };
    default:
      return state;
  }
}
