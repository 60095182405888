import "./Reward.scss";
import MainLayout from "../../layouts/MainLayout";
// import LogoTop from "../../assets/images/bg/Logo.svg";
// import Text from "../../assets/images/oth/text-lite.svg";
import { ROUTES } from "../../lib/consts";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
// import { QuestionItem } from "../../interface/api";
import { useDispatch, useSelector } from "react-redux";
// import {
//   isFirstGame,
//   setIndividualScore,
// } from "../../store/actions/authAction";
import { useAuthentication } from "../../hooks/useAuthentication";
import { RootState } from "../../store/store";
import { GA_EVENTS, gtagTrackEvent } from "../../lib/utils";

interface QuestionItem {
  id: number;
  question: string;
  option1: string;
  option2: string;
  option3: string;

  correctOption: number;
}

interface redeemAnswer {
  id: number;
  option: number;
  gameKey: string;
}

export const Reward = () => {
  const { isLoggedIn } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<QuestionItem | null>(null);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | any>(null);
  const [optionClicked, setOptionClicked] = useState<boolean>(false);
  const location = useLocation();
  const cashback = location.state?.cashback || 0; // Default to 0 if no cashback is provided
  //   const GameKey = useSelector((state: RootState) => state.authReducer.gameKey);

  useEffect(() => {
    // fetchNextQuestion();
  }, []);

  const handleOptionClick = async (option: number) => {
    // if (!optionClicked) {
    // setOptionClicked(true);
    setSelectedOption(option);

    // Simulate answer checking for now
    const isAnswerCorrect = option === question?.correctOption;
    setIsCorrect(isAnswerCorrect);

    // if (isAnswerCorrect) {
    //   //   gtagTrackEvent(GA_EVENTS.PAYMENT_PAGE);
    //   navigate(ROUTES.PAYMENT_PAGE); // Navigate to payment page on correct answer
    // }
    // }
  };
  const handleSubmit = () => {
    navigate(ROUTES.REDEEM);
  };

  return (
    <MainLayout className="register-page">
      <div className="redeem-reward-form">
        <div className="reward-container">
          {/*<img src={Text} alt="logo-top" />*/}
          <p className="reward-title">THANK YOU</p>
          {/*<p className="reward-title-sub">YOU WON</p>
  <p className="reward-amt">₹{cashback}</p>*/}
          <p className="reward-title-sub">YOUR ANSWER HAS BEEN REGISTERED</p>

          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            // disabled={!optionClicked}
          >
            PROCEED
          </button>
        </div>
      </div>
    </MainLayout>
  );
};
