import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
    const { t } = useTranslation();

    return (
        <Popup className="terms-popup" title="Terms & Conditions">
            {/*<p dir="ltr">Coming Soon</p>*/}
            <div style={{ textAlign: "left", padding: ".5em" }}>
                <strong>
                    <p dir="ltr" style={{ textDecoration: "underline" }}>
                        ITC Classmate Invento – Consumer Promotion – Terms &amp; Conditions:
                    </p>
                </strong>

                <strong>
                    <p dir="ltr">About the Programme:</p>
                </strong>
                <ul>
                    <li dir="ltr">
                        <p dir="ltr">
                            The ITC Classmate Invento – Consumer Promotion (‘Program’) is
                            offered by ITC Limited (“ITC”) and conducted by Pine Labs Pvt
                            Limited (Partner Agency/ Pine Labs/ Promotion Party) a company
                            incorporated under the Companies Act, 1956, having its registered
                            office at Unit No.408, 4th Floor, Time Tower, MG Road, DLF QE,
                            Gurgaon – 122002, corporate office at 111, Brigade Manae Court, 5th
                            Block, Koramangala Industrial Estate, Bangalore – 560095.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The offer is available on selected products specifying the limit of
                            this offer in a) Classmate Invento Box - MRP 140 and (b) Classmate
                            Disney Invento - MRP 160 (“Products”).
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            In terms of any conflict between the terms and conditions on this
                            program, the terms herein mentioned shall prevail to the extent of
                            such conflict.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Before participating in the program, please read the terms and
                            conditions given herein. By participating in this Program, each
                            participant is deemed to have read, understood, and accepted the
                            complete terms and conditions of this Program available on
                            classmateinvento.woohoo.in (“Program Website") as well as the
                            decisions of ITC which are final and binding in all matters related
                            to the Program.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Successfully entering the Program and winning the cashback is
                            subject to all requirements set forth herein.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The Program shall be live from 00:00 hours on 05-June-2024 to 23:59
                            hours to 31st October, 2024 ("Program Period”).
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The Program is valid only in India. The Program is open to Citizens
                            of India only.  ITC Classmate Invento – Consumer Promotion offer is
                            valid for a limited period from 05-June-2024 to 31st October,2024
                            (herein after referred as "Program Period") and applicable on select
                            products only. Offer applicable only till Products’ stock lasts.
                        </p>
                    </li>
                </ul>
<strong>
                <p dir="ltr">
                    Steps to participate:
                </p>
</strong>
                <ul>
                    <li dir="ltr">
                        <p dir="ltr">
                            A Participant can send only one entry from a particular mobile
                            number.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            To participate in the Program, participants will have to purchase
                            the Classmate product. They will find the sticker on the box with a
                            unique code and QR code. They can participate by following the steps
                            on the sticker to claim the reward.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Scan the QR code on the pack to land on the microsite
                            (classmateinvento.woohoo.in)
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Enter the unique code present on the pack. Enter name, state, mobile
                            number for OTP validation.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Post validation of the unique code and mobile number, participant
                            will have to select the preferred cashback option amongst Amazon
                            Pay/ Phone Pe/ UPI. Participant who selects wallet cashback will get
                            the voucher code on their registered mobile number. Participants who
                            select UPI will be asked to enter the correct UPI details on the
                            Program Website and transfer will be done to the mentioned UPI ID
                            only. Participant will get an acknowledgement SMS after the
                            submission.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Post verification, the Participant must answer the question ‘What
                            does classmate mean to you?’ and proceed to submit. All Participants
                            with valid entries will be awarded an assured prize of Rs. 20.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The entries will be judged by an internal panel of Pine Labs judges
                            on the basis of quality and creativity of the answer to the
                            question. The decision of Pine Labs and/or the judges regarding
                            selection of winners will be final and binding upon all
                            Participants.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Incomplete and invalid entries and/or submissions received after
                            Program Period will not be considered a valid entry without any
                            further intimation or notice.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Daily Winners shall be selected by Pine Labs’s internal panel of
                            judges on the criteria mentioned above.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            In case the UPI ID is provided is incorrect, no reattempt will be
                            done.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Customer from Tamil Nadu can participate, but irrespective of the
                            answer, they will get only Rs. 20 assured cashbacks.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The cashback (UPI &amp; Wallet) will be processed within 48 Business
                            Hours. Participant will know the amount they win after they get the
                            cashback.
                        </p>
                    </li>
                </ul>
<strong>  
                <p dir="ltr">
                    Terms and Conditions for Assured Cashback and Cashback of up to Rs. 140:
                </p>
</strong>
                <ul>
                    <li dir="ltr">
                        <p dir="ltr">
                            If the customer purchases the product before 5/06/2024, then the
                            customer will not be eligible for participation in the Program until
                            the start of Program Period. All participation prior to the Program
                            Period and after the end date of the Program Period (30/10/2024)
                            will be considered as ineligible and will be summarily rejected.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li dir="ltr">
                        <p dir="ltr">
                            Customer who participates in the programme and purchases the
                            Products and follow the steps to participate given above will be
                            eligible to receive assured cashback of Rs. 20. to up to Rs. 140
                            shall be awarded to the Participants basis the quality and
                            creativity of the answer to the question ‘What does Classmate mean
                            to you?’ The decision of Pine Labs and/or the judges regarding
                            selection of winners will be final and binding upon all Participants
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Customer from the state of Tamil Nadu can participate, but
                            irrespective of the answer, they will get only Rs. 20 cashbacks.
                        </p>
                    </li>
                </ul>
                <p dir="ltr">
                    Participants from Tamil Nadu will avail cashback of flat Rs. 20 only subject
                    to the fulfilment of these terms and conditions.
                </p>
                <p dir="ltr">
                    (a) Classmate Invento Box - MRP 140
                </p>
                <p dir="ltr">
                    (b) Classmate Disney Invento - MRP 160
                </p>
                <ul>
                    <li dir="ltr">
                        <p dir="ltr">
                            To be eligible to get the cashback, the customer will have to follow
                            the steps mentioned in the microsite.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            This promotional offer entitles the customer to receive cashback via
                            Amazon Pay / Phone Pe Cashback code or Bank Transfer. Customers
                            shall be required to read, understand, and accept the Cashback
                            voucher/coupon(s) terms and conditions which may be subject to
                            change at the Partner end.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The cashback cannot be redeemed or exchanged for another Gift
                            Voucher/Gift Card. Also, it cannot be re-validated after expiry
                            date.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The cashback can be claimed only once per person, per mobile number
                            throughout the Program Period.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The cashback (UPI &amp; Wallet) will be processed within 48 Business
                            Hours excluding public holiday.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Note: UPI transfer will be initiated instantly. However, due to
                            delay in bank servers, the cashback may take up to 3 to 4 Business
                            Days.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            In no event ITC/Amazon/Phone Pe-pay or The Partner Agency will be
                            liable for any abuse or misuse of the cash due to the negligence of
                            the customer knowingly/unknowingly
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The details of the UPI linked bank account/ID must be entered and
                            verified by the customer themselves. If the customer enters the
                            incorrect UPI ID, ITC or The Partner Agency will not be held
                            responsible for the non-receipt of cashback or any other claims
                            arising from usage of the incorrect UPI ID.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Once customer selects the choice of receipt of payment/Mode of
                            payment i.e. either UPI/Amazon pay/Phone Pe, it cannot be modified.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC or Pine Labs or any other partner shall not be liable and
                            responsible for any unauthorized and/or fraudulent purchase/s made
                            using of the Cash reward. The holder of the voucher shall be solely
                            responsible for the safe custody of the OTP, cash reward and the
                            credentials mentioned on it.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            UPI/ /Phone Pe /Amazon voucher may change (add to, delete, or amend)
                            its terms from time to time without any prior intimation.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            By participating in the Promotional Offer, the participant agrees to
                            allow ITC or the partner agencies to contact the participant for the
                            purpose of this Promotional offer. Further the participant agrees
                            that the data received during the Promotional Offer by ITC may be
                            used or shared with any agency for any further analysis/ promotion
                            or marketing communications.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            By sharing the information for receipt of cashback, i.e Amazon Pay
                            Wallet details, Phone Pe Wallet details or UPI ID, the Participants
                            consent to the collection, storing, processing and use of such
                            information by the Partner Agency or ITC or its affiliates,
                            associates, agents, contractors and other third parties for the
                            purpose of fulfilling the deliverables under the Program. Such
                            information shall be retained for the duration of the Program Period
                            and for a limited duration thereafter for the purpose of executing
                            and facilitating the Program.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            By sharing their personal information during the Program, the
                            Participants consent to collection, storing, processing and use of
                            personal information i.e. name, address, mobile phone number by ITC,
                            its affiliates, associates, agents, contractors and other third
                            parties.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Personal information given by the Participants shall be used to
                            deliver Prizes (if applicable) and for promotional activities by
                            ITC, its affiliates, associates, agents, contractors. The personal
                            information is being collected by the Partner Agency and shall be
                            retained by the Partner Agency as per applicable law, including RBI
                            guidelines.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The Participants have an option of not providing the personal
                            information as required under this Program, provided that, in such
                            cases, the cashback may not be provided to the extent permitted
                            under applicable law. As applicable under the relevant laws, the
                            Participants also have an option to withdraw their consent in
                            relation to usage of any sensitive personal data and information (as
                            defined in law) they may have provided during the Program Period
                            subject to applicable laws. In such cases, the entry of the
                            Participant shall not be considered and Participant shall not be
                            eligible for the Prizes. The Participants may reach out to
                            &lt;__Consentremoval@pinelabs.com___&gt; for such withdrawals.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            By participating in this Program, the Participants acknowledge and
                            consent to transfer and/or disclosure of their personal information
                            by ITC to any third party authorised by ITC for providing services
                            in relation to this Program.
                        </p>
                    </li>
                </ul>
<strong>
                <p dir="ltr">
                    Other Terms &amp; Conditions:
                </p>
</strong>
                <ul>
                    <li dir="ltr">
                        <p dir="ltr">
                            The offer is for end consumers and not for distributors, retailers,
                            seller, their staff / Families, Employees of ITC or its partner
                            agencies.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            This offer is subject to promotional availability. This offer is not
                            exchangeable for “cash”.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            This offer is not for sale.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            All Entries submitted must comply with these Terms &amp; Conditions.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The Entries submitted:
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot be sexually explicit or suggestive, violent or derogatory of
                            any ethnic, racial, gender, religious, professional or age group,
                            profane or pornographic, or contain nudity;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot promote alcohol, illegal drugs, firearms/weapons (or the use
                            of any of the foregoing), any activities that may appear unsafe or
                            dangerous, or any particular political agenda or message;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot be obscene or offensive, endorse any form of hate or hate
                            group;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot promote terrorist acts such as providing instructions on how
                            to assemble bombs, grenades and other such weapons or provide
                            instructional information about illegal activities;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot defame, misrepresent or contain disparaging or slanderous
                            remarks about ITC, its customers or its products, or other people,
                            products or companies;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot contain trademarks, logos or trade dress owned by others
                            (except ITC’s trademarks, logos or trade dress of Sunrise Pure), or
                            advertise or promote any brand or product of any kind (except ITC’s
                            products marketed under the brand Sunrise Pure), without permission,
                            or contain any personal identification, such as license plate
                            numbers, personal names, e-mail addresses or street addresses;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Must be original and cannot contain copyrighted materials owned by
                            others (including literary works, photographs, sculptures, paintings
                            and other works of art, stills, or images published on or in
                            television, movies, the Internet or other media, except such
                            materials owned by ITC) without permission and cannot be plagiarized
                            from any source;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot contain background artwork, unless it is an original work of
                            the Participant;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot communicate messages or images inconsistent with the positive
                            images and/or goodwill to which ITC wishes to associate;
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Cannot depict, and cannot itself, be in violation of any law.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Participants making statements which are contrary to existing law,
                            bye-laws, public policy or established good practices shall be
                            disqualified from further participating in the Contest.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            By submitting an Entry the Participant warrants and represents that
                            it: (a) is the Participant’s original work, (b) has not been
                            previously published, (c) has not won previous awards, (d) does not
                            infringe upon the copyright, trademarks, rights of privacy,
                            publicity or other intellectual property or other rights of any
                            person or entity; (e) that the Participant has obtained permission
                            from any other person whose name, likeness or image is used in the
                            photo and (f) and that publication of the entry via various media
                            including Web posting, will not infringe any third party rights. The
                            Participant will indemnify and hold harmless ITC and the Promotion
                            Parties from any claims to the contrary.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            By entering, the Participant acknowledges that ITC has no obligation
                            to use or post any Entry submitted by the Participant.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC LIMITED and the Partner Agency shall not be responsible for
                            (including but not limited to) for any SPAM generated messages, for
                            the Operator Code not being displayed on the user's mobile phones,
                            for any SMS message delivery failures, or any lost, late or
                            misdirected computer transmission or network, electronic failures or
                            any kind of any failure to receive entries owing to transmission
                            failures or due to any technical reason
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC LIMITED or its Partner Agency will not be responsible for any
                            NDNC (National Do Not Call) Registry regulation that will come into
                            play. All Participants who participate will agree as per the terms
                            and conditions that even if they are registered under NDNC, DND (Do
                            Not Disturb), ITC LIMITED or its Agency will have all the authority
                            to call such shortlisted winners by virtue of them having
                            voluntarily participated in the Contest
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC or its Partner Agency, its officers, directors, employees,
                            agents, successors, or assigns are not liable and do not have
                            obligation with respect to (i) notifications, communications or
                            materials sent by a Participant that are lost, late, misdirected,
                            garbled, inaccurate, incomplete, illegible, unintelligible, damaged
                            or otherwise not received by ITC, in whole or in part, due to
                            computer, post office or technical error of any kind, (ii)
                            telephone, electronic, hardware, software, network, Internet or
                            computer malfunctions, failures or difficulties, (iii) power-related
                            or weather-related events of any kind, and/or to any event not under
                            ITC direct control, (iv) entries or other communications from
                            customers that contain inaccurate information or do not comply with
                            these rules, (v) errors, omissions, interruptions, deletions,
                            defects, delays in operation or transmissions, theft, destruction,
                            unauthorized access or alterations by Participants or third parties,
                            (vi) any damages or losses of any kind resulting from acceptance,
                            possession or use of any Prize. ITC, in their sole discretion,
                            reserves the right to disqualify any person tampering with the entry
                            process or the operation of the Contest or otherwise violating these
                            rules. ITC further reserves the right to cancel, terminate or modify
                            this Contest if this Contest cannot be completed as planned because
                            of infection by computer virus, bugs, tampering, unauthorized
                            intervention or technical failures of any sort.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            No liability or responsibility is assumed by ITC or the Promotion
                            Parties resulting from Participant's participation in or attempt to
                            participate in the Contest or ability or inability to upload or
                            download any information in connection with the Contest. No
                            responsibility or liability is assumed by ITC or Partner Agency for
                            technical problems or technical malfunction arising in connection
                            with any of the following occurrences which may affect the operation
                            of the Contest: Hardware or software errors; faulty computer,
                            telephone, cable, satellite, network, electronic or Internet
                            connectivity or other online or network communication problems;
                            errors or limitations of any Internet providers, servers, hosts or
                            other providers; garbled, jumbled or faulty data transmissions;
                            failure of any online transmissions to be sent or received; lost,
                            late, delayed or intercepted transmissions; inaccessibility of the
                            Web Site in whole or in part for any reason; traffic congestion on
                            the Internet or Web Site; unauthorized human or non-human
                            intervention of the operation of the Contest, including without
                            limitation, unauthorized tampering, hacking, theft, virus, bugs, or
                            worms; or destruction of any aspect of the Contest, or loss,
                            miscount, misdirection, inaccessibility or unavailability of an
                            email account used in connection with the Contest. ITC or Partner
                            Agency is not responsible for any typographical errors in the
                            announcement of prizes or these Terms and Conditions, or any
                            inaccurate or incorrect data contained on the website. ITC or
                            Partner Agency is not responsible for any personal injury or
                            property damage or losses of any kind which may be sustained to
                            user's or any other person's computer equipment resulting from
                            participation in the Contest. All Entries become the sole property
                            of ITC, and will not be acknowledged or returned.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC or Partner Agency shall under no circumstances be liable,
                            whether jointly or severally, for any loss or damage suffered or
                            sustained (including but not limited to consequential loss),
                            including for personal injury or property damage suffered or
                            sustained or any costs incurred, as a result of acceptance of any
                            Prize.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The Contest shall be judged by ITC and the decision of ITC will be
                            final and binding on the Participants with regard to any dispute
                            arising out of this activity.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            In the event of death of the Confirmed Winner, no claim from the
                            nominee or his/her legal heirs of the Confirmed Winner shall be
                            entertained for receiving the prize.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            This voucher/code has no monetary value, is non-transferable and
                            cannot be used in conjunction with any other promotional offers.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            One voucher / code may be used per person, per mobile number during
                            the campaign period.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC or the Partner Agency is not responsible for any loss or theft
                            of the vouchers.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            If this program is interfered with in any way or is not capable of
                            being conducted as reasonably anticipated due to any reason beyond
                            the reasonable control of the Partner Agency or ITC, including but
                            not limited to technical difficulties, unauthorized intervention or
                            fraud, the Partner Agency or ITC reserves the right, in its sole
                            discretion, to the fullest extent permitted by law (a) to invalidate
                            any customer; or (b) subject to any written directions from a
                            regulatory authority (c) to modify, suspend, terminate or cancel the
                            promotion, as appropriate.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC or its Partner Agency accepts no responsibility, financial or
                            otherwise for the misuse of vouchers and are not liable for any
                            personal loss or injury and cannot be held responsible for any
                            disagreement concerning the quality/ availability of featured
                            offers.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            ITC, or the Partner Agency is acting as an executing agency for this
                            promotional offer and holds no legal and financial liability on
                            unclaimed offers, incentives, Vouchers, etc beyond the applicable
                            period of the promotional offer. Partner Agency is acting as an
                            service provider to ITC for this promotional offer and holds no
                            legal and financial liability on unclaimed offers, incentives,
                            vouchers, etc.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The Terms and Conditions of the offer can be changed during the
                            promotional period by ITC Limited or The Partner Agency
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Any dispute or difference in connection with the programme share be
                            governed by the laws of India and shall be subject to the exclusive
                            jurisdiction of the courts in New Delhi.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            For more details you can write to us on
                            <a href="mailto:classmatesupport@pinelabs.com">
                                classmatesupport@pinelabs.com
                            </a>
                            .
                        </p>
                    </li>
                </ul>


            </div>
        </Popup>
    );
};

export default TermsPopup;
