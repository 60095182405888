import ContactUsPopup from "./ContactUsPopup";
import TermsPopup from "./TermsPopup";
import HowTORedeemPopup from "./HowToRedeemPopup";
import PrivacyPopup from "./PrivacyPopup";
import ConsentPopup from "./ConsentPopup";

const popups = {
  ContactUsPopup,
  ConsentPopup,
  TermsPopup,
  HowTORedeemPopup,
  PrivacyPopup,
};

export default popups;
