import "./Quiz.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";
import API from "../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface QuestionItem {
  id: number;
  question: string;
  correctAnswer: string;
}

export const Quiz = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userAnswer, setUserAnswer] = useState("");
  const question: QuestionItem = location.state.questionData;

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserAnswer(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await API.submitQuizAnswer(userAnswer);
      {
        /* const isAnswerCorrect =
        userAnswer.toLowerCase() === question.correctAnswer.toLowerCase();
      navigate(ROUTES.REWARD, {
        state: { cashback: response.cashback, isAnswerCorrect },
      });*/
      }
      navigate(ROUTES.REWARD);
    } catch (error) {
      console.error("Error submitting quiz answer:", error);
    }
  };

  return (
    <MainLayout className="register-page">
      <div className="quiz-form">
        <div className="game-container">
          <p className="quiz-title">ANSWER THE QUESTION</p>
          {question && (
            <div className="quiz-section">
              <p className="quiz-question">What does classmate mean to you?</p>
              <textarea
                className="text-area"
                value={userAnswer}
                onChange={handleAnswerChange}
                placeholder="Type Answer"
                rows={6} // You can adjust the number of rows as needed
              />
              <button className="btn btn-primary" onClick={handleSubmit}>
                SUBMIT
              </button>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
